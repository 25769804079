import copy from 'copy-to-clipboard';
import { FC, FormEvent, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { PrimaryButton, Loader, Modal, Button, Text } from '@calm-web/design-system';
import useForm, { stringFromModelValue, validation } from '@calm-web/use-form';

import CellTitle, { Subtitle } from '@/components/ui/CellTitle';
import { EnvConfig } from '@/env_config';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { usePartnerAdmin } from '@/hooks/api/usePartnerAdmin';
import { useInviteSftpAdmin, useSftpConfig, useUploadSshKey } from '@/hooks/api/useSftpConfig';
import { setBannerMessage } from '@/store/actions/setBannerMessage';

import messages from './messages';
import {
	HelperLink,
	CardStyled,
	Row,
	RowForm,
	FormInputStyled,
	SectionStyled,
	StyledTextArea,
	ButtonContainer,
} from './styles';

const { sftpUrl } = EnvConfig;
const SFTP_DOCS_LINK = '/docs/sftp-instructions';

const SftpAdminInfo: FC = () => {
	const { formatMessage } = useIntl();

	return (
		<Subtitle>
			{formatMessage(messages.sftpSubtitle, {
				link: (...chunks: ReactNode[]) => (
					<HelperLink href={`${SFTP_DOCS_LINK}#contact`} target="_blank" rel="noopener noreferrer">
						{chunks}
					</HelperLink>
				),
			})}
		</Subtitle>
	);
};

const SftpAdmin: FC<{ itAdminEmail: string }> = ({ itAdminEmail }) => {
	const { formatMessage } = useIntl();
	const formProps = useForm('sftpIntegration', {
		initialModel: { itAdminEmail },
		validation: {
			itAdminEmail: validation.validateOrFail([
				{
					rules: [validation.email],
					errorResult: 'Please enter a valid email',
				},
			]),
		},
	});
	const [showNoMatchModal, setShowNoMatchModal] = useState(false);
	const partner = useDefinedPartner();
	const { data: admins = [] } = usePartnerAdmin(partner.id);
	const emailProps = formProps.bindWithErrorProps('itAdminEmail');
	const [inviteAdmin, { loading }] = useInviteSftpAdmin();

	const onSubmit = async (e: FormEvent): Promise<void> => {
		e.preventDefault();
		const email = stringFromModelValue(formProps.model.itAdminEmail);
		if (!email) {
			return;
		}
		const domainRegex = /(?<=@)[^.]+(?=\.)/g;
		const hasMatchingDomain = admins.some(
			({ email: adminEmail }) => adminEmail?.match(domainRegex) === email.match(domainRegex),
		);

		if (admins.length && !hasMatchingDomain) {
			setShowNoMatchModal(true);
		} else {
			await inviteAdmin({ itAdminEmail: email });
		}
	};

	const addAdmin = async (): Promise<void> => {
		const email = stringFromModelValue(formProps.model.itAdminEmail);
		if (email) {
			await inviteAdmin({ itAdminEmail: email });
			setShowNoMatchModal(false);
		}
	};

	const isDisabled = !formProps.validation.isValid || !formProps.dirtyState.itAdminEmail?.hasChanged;
	return (
		<>
			<SectionStyled>
				<SftpAdminInfo />
			</SectionStyled>
			<SectionStyled>
				<RowForm onSubmit={onSubmit}>
					<div>
						<FormInputStyled
							{...emailProps}
							data-testid="it-admin-email-input"
							label={formatMessage(messages.itAdminEmailInput)}
						/>
					</div>

					<div>
						<PrimaryButton isDisabled={isDisabled} type="submit" isLoading={loading}>
							{formatMessage(messages.itAdminEmailSubmit)}
						</PrimaryButton>
					</div>
				</RowForm>
			</SectionStyled>
			<Modal
				title="No matching email domain"
				isOpen={showNoMatchModal}
				canClose={false}
				closeModal={() => setShowNoMatchModal(false)}
				footer={
					<ButtonContainer>
						<Button
							onPress={() => setShowNoMatchModal(false)}
							hideShadow
							backgroundColor="transparent"
							textColor="blue3"
						>
							Cancel
						</Button>
						<PrimaryButton onPress={addAdmin}>Add Anyway</PrimaryButton>
					</ButtonContainer>
				}
			>
				<Text el="p">
					The domain of the email address you&apos;ve entered does not match the domain used for any other
					admin.
				</Text>
				<Text el="p" noMargin>
					Do you wish to use the <b>{stringFromModelValue(formProps.model.itAdminEmail)}</b> address for your
					IT Admin?
				</Text>
			</Modal>
		</>
	);
};

const SshInfo: FC<{}> = () => {
	const { formatMessage } = useIntl();
	return (
		<Subtitle>
			{formatMessage(messages.sshKeyInfo, {
				linebreak: <br />,
				sshKeyLink: (...chunks: ReactNode[]) => (
					<HelperLink href={`${SFTP_DOCS_LINK}#ssh-key`} target="_blank" rel="noopener noreferrer">
						{chunks}
					</HelperLink>
				),
				formattingLink: (...chunks: ReactNode[]) => (
					<HelperLink href={`${SFTP_DOCS_LINK}#eligibility-file`} target="_blank" rel="noopener noreferrer">
						{chunks}
					</HelperLink>
				),
			})}
		</Subtitle>
	);
};

const SftpDetails: FC<{ username: string }> = ({ username }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const formProps = useForm('sftpDetailsForm', {
		initialModel: {
			username: username,
			sftpUrl,
		},
	});

	const copySftpUsername = (): void => {
		copy(username, {
			format: 'text/plain',
			onCopy: () => {
				dispatch(
					setBannerMessage({
						message: 'SFTP username copied to clipboard',
						isError: false,
						flash: true,
					}),
				);
			},
		});
	};

	const copySftpUrl = (): void => {
		copy(sftpUrl, {
			format: 'text/plain',
			onCopy: () => {
				dispatch(
					setBannerMessage({
						message: 'SFTP url copied to clipboard',
						isError: false,
						flash: true,
					}),
				);
			},
		});
	};

	const usernameProps = formProps.bindWithErrorProps('username');
	const sftpUrlProps = formProps.bindWithErrorProps('sftpUrl');

	const sftpUsernameId = 'sftp-username';
	const sftpUrlId = 'sftp-url';

	return (
		<SectionStyled>
			<Row>
				<div>
					<FormInputStyled
						{...usernameProps}
						disabled
						id={sftpUsernameId}
						data-testid="sftp-username"
						label={formatMessage(messages.sftpUsernameLabel)}
					/>
				</div>
				<div>
					<PrimaryButton onPress={copySftpUsername} aria-describedby={sftpUsernameId}>
						{formatMessage(messages.sftpUsernameCopyText)}
					</PrimaryButton>
				</div>
			</Row>
			<Row>
				<div>
					<FormInputStyled
						{...sftpUrlProps}
						disabled
						id={sftpUrlId}
						data-testid="sftp-url"
						label={formatMessage(messages.sftpUrlLabel)}
					/>
				</div>
				<div>
					<PrimaryButton onPress={copySftpUrl} aria-describedby={sftpUrlId}>
						{formatMessage(messages.sftpUrlCopyText)}
					</PrimaryButton>
				</div>
			</Row>
		</SectionStyled>
	);
};

const SshKey: FC<{ itAdminEmail: string; isConfigured: boolean }> = ({ itAdminEmail, isConfigured }) => {
	const { formatMessage } = useIntl();
	const formProps = useForm('sshKey', {
		initialModel: { sshKey: '' },
		validation: {
			sshKey: validation.validateOrFail([
				{
					rules: [validation.required, validation.minLength(4)],
					errorResult: 'Please enter a valid SSH key.',
				},
			]),
		},
	});

	const [uploadSshKey, { loading }] = useUploadSshKey();

	const sshKeyProps = formProps.bindWithErrorProps('sshKey');

	const onSubmit = async (e: FormEvent): Promise<void> => {
		e.preventDefault();
		const sshKey = stringFromModelValue(formProps.model.sshKey);
		if (!sshKey) {
			return;
		}
		await uploadSshKey({ sshKey });
	};

	return (
		<>
			<SectionStyled>
				<SshInfo />
			</SectionStyled>
			<SectionStyled>
				<form onSubmit={onSubmit}>
					<StyledTextArea
						{...sshKeyProps}
						placeholder={isConfigured ? '********' : undefined}
						data-testid="ssh-key-input"
						label={formatMessage(messages.sshKeyInput)}
					/>

					<div>
						<PrimaryButton type="submit" isDisabled={!formProps.validation.isValid} isLoading={loading}>
							{formatMessage(messages.sshKeySubmit)}
						</PrimaryButton>
					</div>
				</form>
			</SectionStyled>
		</>
	);
};

const HelpSection: FC<{}> = () => {
	const { formatMessage } = useIntl();

	return (
		<SectionStyled>
			<Subtitle>
				{formatMessage(messages.helpText, {
					link: (...chunks: ReactNode[]) => (
						<HelperLink
							href="https://support.calm.com/hc/en-us?solvvyProvidedByEmployer"
							target="_blank"
							rel="noopener noreferrer"
						>
							{chunks}
						</HelperLink>
					),
				})}
			</Subtitle>
		</SectionStyled>
	);
};

const SftpIntegration: FC = () => {
	const { formatMessage } = useIntl();
	const { data: sftpConfig, loading } = useSftpConfig();

	if (loading || !sftpConfig) {
		return <Loader color="gray1" />;
	}

	return (
		<CardStyled>
			<CellTitle>{formatMessage(messages.sftpTitle)}</CellTitle>
			<SftpAdmin itAdminEmail={sftpConfig.it_admin_email} />
			{sftpConfig.it_admin_email && (
				<SshKey itAdminEmail={sftpConfig.it_admin_email} isConfigured={!!sftpConfig.sftp_username} />
			)}
			{sftpConfig.sftp_username && <SftpDetails username={sftpConfig.sftp_username} />}
			<HelpSection />
		</CardStyled>
	);
};

export default SftpIntegration;
