import { defineMessages } from 'react-intl';

export default defineMessages({
	sftpTitle: {
		id: 'createPartner.sftpTitle',
		defaultMessage: 'SFTP Integration',
		description: 'A title above a section where the user can edit SFTP integration',
	},
	sftpSubtitle: {
		id: 'createPartner.sftpSubtitle',
		defaultMessage: `To get started with setting up your integration you'll need to enter the contact information of your IT Administrator, and they can reference the following <link>instructions</link> which we'll send via email.`,
		description: 'Subtitle for SFTP integration',
	},
	itAdminEmailInput: {
		id: 'sftp.itAdminEmailInput',
		defaultMessage: `IT Administrator email`,
		description: 'Label for IT admin email input',
	},
	itAdminEmailSubmit: {
		id: 'sftp.itAdminEmailSubmit',
		defaultMessage: `Add new admin`,
		description: 'Text for IT admin email submit button',
	},
	sshKeyInput: {
		id: 'sftp.sshKeyInput',
		defaultMessage: `SSH key`,
		description: 'Label for SSH key input',
	},
	sshKeyInfo: {
		id: 'sftp.sshKeyInfo',
		defaultMessage: `Once you’ve generated your public SSH key please enter it here and then proceed to setup a secure file transfer to Calm using the tool of your choice and the credential information below. See technical documentation for more information on SSH requirements in our <sshKeyLink>integration doc</sshKeyLink>.
{linebreak}
{linebreak}
		Please ensure the file is formatted correctly by following this <formattingLink>example</formattingLink>.`,
		description: 'Label for SSH key input',
	},
	sshKeySubmit: {
		id: 'sftp.sshKeySubmit',
		defaultMessage: `Upload SSH key`,
		description: 'Text for SSH key submit button',
	},
	sftpUsernameLabel: {
		id: 'sftp.sftpUsernameLabel',
		defaultMessage: 'SFTP Username',
		description: 'Label for SFTP username',
	},
	sftpUsernameCopyText: {
		id: 'sftp.sftpUsernameCopyText',
		defaultMessage: 'Copy',
		description: 'Text for copy button for SFTP username',
	},
	sftpUrlLabel: {
		id: 'sftp.sftpUrlLabel',
		defaultMessage: 'SFTP URL',
		description: 'Label for SFTP URL',
	},
	sftpUrlCopyText: {
		id: 'sftp.sftpUrlCopyText',
		defaultMessage: 'Copy',
		description: 'Text for copy button for SFTP URL',
	},
	helpText: {
		id: 'sftp.helpText',
		defaultMessage:
			'Need more help? Please reach out to your dedicated Customer Success Manager for additional support. If you do not have a Customer Success Manager, please reach out to our Support team <link>here</link>.',
		description: 'Help message prompting users to reach out to their customer success manager.',
	},
});
