import { FC } from 'react';
import { useLocation } from 'react-router';

import { Loader } from '@calm-web/design-system';

import { useNumChildren } from '@/hooks/api/usePartnerRelations';
import { useShouldShowSftp } from '@/hooks/api/useSftpConfig';
import { usePermissions } from '@/hooks/auth';
import { useIsMobile } from '@/hooks/layout/useIsMobile';
import { Partner } from '@/types/store/reducers';
import { isCalmHealthProductSKU, isSelfServePlan } from '@/utils/SkuUtils';

import AccountNavLink from './AccountNavLink';
import { StyledNav } from './styles';

interface SubNavProps {
	partner: Partner;
	shouldShowReportingTab?: boolean;
}

const SubNav: FC<SubNavProps> = ({ partner, shouldShowReportingTab }) => {
	const { data: shouldShowSftp, loading } = useShouldShowSftp();
	const [hasValidPermissions, actions] = usePermissions();
	const isSelfServe = isSelfServePlan(partner?.vouched_plan_sku);
	const isCalmHealth = isCalmHealthProductSKU(partner?.product_sku);
	const isAccountManager = hasValidPermissions('client_id', [actions.READ]);
	const [isMobile] = useIsMobile();
	const { numChildren } = useNumChildren(partner?.id);
	const { hash } = useLocation();
	const activePage = hash === '' ? '#details' : hash;

	if (loading) {
		return <Loader color="gray1" />;
	}

	return (
		<StyledNav aria-label="Account page navigation">
			<AccountNavLink to={`account#details`} id="details" data-testid="account-details-link">
				Account Details
			</AccountNavLink>
			{shouldShowSftp && !isCalmHealth && (
				<AccountNavLink to={`account#integration`} id="integration" data-testid="account-integration-link">
					Integration
				</AccountNavLink>
			)}
			<AccountNavLink to={`account#branding`} id="branding" data-testid="account-branding-link">
				{isCalmHealth ? 'Branding' : 'Branding & Benefits'}
			</AccountNavLink>
			<AccountNavLink
				to={`account#emailAndSurvey`}
				id="emailAndSurvey"
				data-testid="account-email-survey-link"
			>
				{isCalmHealth ? 'Surveys & Recommendations' : 'Emails & Surveys'}
			</AccountNavLink>
			{shouldShowReportingTab && (
				<AccountNavLink to={`account#reporting`} id="reporting" data-testid="account-reporting-link">
					Reporting
				</AccountNavLink>
			)}
			{isMobile && isSelfServe && hasValidPermissions('update_plan_details', [actions.READ]) && (
				<AccountNavLink
					to={`account#planAndPayment`}
					id="planAndPayment"
					data-testid="account-plan-payment-link"
				>
					Plan &amp; Payment
				</AccountNavLink>
			)}
			{isCalmHealth && (
				<AccountNavLink
					to={`account#healthConfig`}
					id="healthConfig"
					data-testid="account-health-config-link"
				>
					Health Config
				</AccountNavLink>
			)}
			{isAccountManager && (
				<>
					<AccountNavLink to={`account#admin`} id="admin" data-testid="account-admin-link">
						Calm Admin
					</AccountNavLink>
					{numChildren || activePage === '#childPartners' ? (
						<AccountNavLink
							to={`account#childPartners`}
							id="childPartners"
							data-testid="account-childPartners-link"
						>
							Children
						</AccountNavLink>
					) : null}
				</>
			)}
		</StyledNav>
	);
};

export default SubNav;
